<template>
  <div class="div">
    <div
      class="card scrape-product"
    >
      <div class="card-body text-center">
        <h2 class="text-primary">
          Tambah Produk dari External
        </h2><p class="card-text mb-2">
          Tambahkan produk dari tokopedia dengan menyertakan URL produk di bawah
        </p>

        <form
          class="faq-search-input form-row"
          @submit.prevent="scrape"
        >
          <div class="col">
            <div
              role="group"
              class="input-group input-group-merge"
            >
              <!----><div class="input-group-prepend">
                <div class="input-group-text">
                  <feather-icon
                    icon="LinkIcon"
                    size="16"
                  />
                </div>
              </div><input
                id="searchbar"
                v-model="url"
                type="text"
                placeholder="Masukkan URL produk"
                class="form-control form-control-lg"
              ><!---->
            </div>
          </div>

          <div class="col-auto">
            <button
              class="btn btn-primary btn-lg d-flex align-items-center"
              type="submit"
              :disabled="loadingSubmit"
            >
              <b-spinner
                v-if="loadingSubmit"
                class="mr-50"
                small
              />
              <feather-icon
                v-else
                icon="PlusIcon"
                size="16"
                class="mr-50"
              />
              Tambah
            </button>
          </div>
        </form>
      </div><!----><!---->
    </div>

    <div
      v-if="data"
      class="card"
    >
      <div class="card-body">
        <FormView
          :data="data"
          :fields="$store.getters['product/tableFields']"
          lazy
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { BSpinner } from 'bootstrap-vue'
import FormView from '@/layouts/components/FormView.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ScrapeProduct',
  components: { FormView, BSpinner },
  data() {
    return {
      url: '',
      data: null,
    }
  },
  computed: {
    ...mapState('product', ['loadingSubmit']),
  },
  methods: {
    scrape() {
      this.$store.dispatch('product/scrape', this.url)
        .then(data => {
          this.data = data

          console.log(this.data = data)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sukses',
              icon: 'CheckIcon',
              text: 'Produk berhasil ditambahkan',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Gagal',
              icon: 'AlertTriangleIcon',
              text: 'Harap coba beberapa saat lagi atau hubungi Admin Miniapoly.',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style scoped>
.scrape-product {
  background-image: url(../assets/images/pages/leaf-bg.png);
  background-size: cover;
  background-color: rgba(115, 103, 240, .12) !important;
}

@media (min-width: 992px) {
  .scrape-product .card-body {
    padding: 8rem !important;
  }
}
</style>
